jQuery(($) => {
	var ppp = 3;
	var pageNumber = 2;
	var pp = 21;

	$(".more-press-container.blog .more-posts.read--more").on(
		"click",
		function () {
			console.log("clicked");

			var button = $(this);
			var btnText = button.text();
			button.text("Loading...");

			var str =
				"&pageNumber=" +
				pageNumber +
				"&ppp=" +
				ppp +
				"&type=post&action=more_post_ajax";

			$.ajax({
				type: "POST",
				dataType: "html",
				url: ajax_posts.ajaxurl,
				data: str,
				beforeSend: function (jqXHR) {
					button.text("Loading...");
				},
				success: function (data) {
					if (data) {
						$(".row.press--content .press-grid").append(data);
						button.text(btnText);
					} else {
						button.hide();
						console.log("No More Items");
					}
				},
				afterSend: function (jqXHR) {
					button.text("More posts").prev().before(data);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$loader.html(
						jqXHR + " :: " + textStatus + " :: " + errorThrown
					);
				},
			});
			pageNumber++;
		}
	);

	$(".more-press-container.newsroom .more-posts.read--more").on(
		"click",
		function () {
			console.log("clicked");

			var button = $(this);
			var btnText = button.text();
			button.text("Loading...");

			var str =
				"&pageNumber=" +
				pageNumber +
				"&ppp=" +
				ppp +
				"&type=post&action=more_post_ajax_newsroom";

			$.ajax({
				type: "POST",
				dataType: "html",
				url: ajax_posts.ajaxurl,
				data: str,
				beforeSend: function (jqXHR) {
					button.text("Loading...");
				},
				success: function (data) {
					if (data) {
						$(".row.press--content .press-grid").append(data);
						button.text(btnText);
					} else {
						button.hide();
						console.log("No More Items");
					}
				},
				afterSend: function (jqXHR) {
					button.text("More posts").prev().before(data);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$loader.html(
						jqXHR + " :: " + textStatus + " :: " + errorThrown
					);
				},
			});
			pageNumber++;
		}
	);

	$(".more-archive-container .more-posts.read--more").on(
		"click",
		function () {
			console.log("clicked");

			var button = $(this);
			var btnText = button.text();
			button.text("Loading...");

			var str =
				"&pageNumber=" +
				pageNumber +
				"&pp=" +
				pp +
				"&type=archive&action=more_archive_ajax";

			console.log(pp);
			console.log(pageNumber);

			$.ajax({
				type: "POST",
				dataType: "html",
				url: ajax_posts.ajaxurl,
				data: str,
				beforeSend: function (jqXHR) {
					button.text("Loading...");
				},
				success: function (data) {
					if (data) {
						$(".row.archive--content").append(data);
						button.text(btnText);
					} else {
						button.hide();
						console.log("No More Items");
					}
				},
				afterSend: function (jqXHR) {
					button.text("More posts").prev().before(data);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$loader.html(
						jqXHR + " :: " + textStatus + " :: " + errorThrown
					);
				},
			});
			pageNumber++;
		}
	);
});
